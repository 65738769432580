<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Event Dialog"
    color="info"
    size="lg"
  >
    <template #header>
      <h6 class="modal-title text-center">{{title}}</h6>
    </template>

    <div class='modal-body'>
      <v-table id="eventDialogTable" :columns="columns" :data="data" :options="options" @on-click-row="onClickRow"></v-table>
    </div>

    <template #footer>
      <CButton @click="cancel()" color="light">닫기</CButton>
    </template>
  </KModal>
</template>

<script>
import { EVENT_TABLE_OPTIOINS, EVENT_TABLE_COLUMS } from "@/constants/eventSettings"
import { IMAGES } from "@/constants/constant"
import utils from '@/services/utils'

export default {
  name: 'EventDialog',
  created() {
    this.sites = this.$store.state.auth.user_info.sites

    _.find(this.columns, { field: 'close' }).visible = false;
    _.find(this.columns, { field: 'device_address' }).visible = false;
    _.find(this.columns, { field: 'created_at' }).formatter = utils.dateFormatter;
    _.find(this.columns, { field: 'is_closed' }).formatter = this.closeFormatter;
    _.find(this.columns, { field: 'level' }).formatter = this.imageFormatter2;
    _.find(this.columns, { field: 'site' }).formatter = this.siteFormatter;
    _.find(this.columns, { field: 'device_name' }).formatter = this.nameFormatter;
    _.find(this.columns, { field: 'sensor_type' }).formatter = this.sensorTypeFormatter;
  },
  data () {
    return {
      showModal: false,
      title: '',
      options: _.cloneDeep(EVENT_TABLE_OPTIOINS),
      columns: _.cloneDeep(EVENT_TABLE_COLUMS),
      data: [],
    }
  },
  methods: {
    nameFormatter(value, row, index) {
      return _.get(row, "device_name");
    },
    getSiteName(site_guid) {
      var site = this.sites.filter(function(e) {
        return e.guid == site_guid;
      })[0];
      return _.get(site, "name", "");
    },
    siteFormatter(value, row, index) {
      return this.getSiteName(value);
    },
    closeFormatter(value, row, index) {
      return value == true ? 'Y' : 'N';
    },
    imageFormatter2(value, row, index) {
      var imgsrc;
      Object.keys(IMAGES).some(function(key, i) {
        if ((row.sensor_type % 64) === IMAGES[key].type && row.level === IMAGES[key].level) {
          imgsrc = IMAGES[key].name;
          return true;
        }
      });
      if(imgsrc == undefined)
          imgsrc = "img/humidity-lack.png";

      return '<img src=' + imgsrc + ' height=' + IMAGES.size.height + 'width=' + IMAGES.size.width + ' / > '
    },
    sensorTypeFormatter(value, row, index) {
      return utils.getSensorTypeName(row.sensor_type, row.sensor_id, true);
    },
    onClickRow(field, value, row) {
      this.$emit('click', field, value, row)
      this.cancel();
    },
    cancel() {
      this.showModal = false;
    },
    show(field, data) {
      var title = undefined
      var sensor_type_battery = 1
      var sensor_type_humidity = 12
      var sensor_type_pms = 28
      var sensor_type_srds = 8
      var sensorType = sensor_type_battery
      var sensor_level = field.val
      switch (field.key[0]) {
        case 'H': title = "토양수분 "; sensorType = sensor_type_humidity; break;
        case 'P': title = "초미세먼지 "; sensorType = sensor_type_pms; break;
        case 'B': title = "배터리 "; sensorType = sensor_type_battery; break;
        case 'D': title = "위험등급 "; sensorType = sensor_type_srds; break;
        default: return;
      }
      switch (field.key[1]) {
        case 'G': title += "(좋음)"; sensor_level = 0; break;
        case 'N': {
          if (field.key[0] === 'H') {
            title += "(정상)"; sensor_level = 0;
          } else {
            title += "(보통)"; sensor_level = 1;
          }
          break;
        }
        case 'E': title += "(과다)"; sensor_level = 1; break;
        case 'C': {
          if (field.key[0] === 'P') title += "(나쁨)";
          else title += "(주의)";
          sensor_level = 2;
          break;
        }
        case 'L': title += "(부족)"; sensor_level = 3; break;
        case 'W': {
          if (field.key[0] === 'P') title += "(매우나쁨)";
          else title += "(경고)";
          sensor_level = 3;
          break;
        }
      }

      if (sensor_level === 0) {
        return;
      }

      var filtered = _.filter(data, function(o) {
        if (o.sensor_type === sensorType && o.level === sensor_level) {
          return true;
        } else {
          return false;
        }
      });
      this.options.height = filtered.length > 3 ? 280 : 155

      this.title = title;
      this.data = filtered;
      this.showModal = true;
    }
  }
}
</script>